<template>
	<div class="home">
		<table-roles />
	</div>
</template>

<script>
import tableRoles from '@/components/pages/maint/roles/tableRoles.vue';

export default {
	components: {
		'table-roles': tableRoles,
	}
};
</script>

<style>

</style>
