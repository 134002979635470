<template>
	<v-dialog v-model="dialog" persistent max-width="50%">
		<template v-slot:activator="{ on }">
			<app-add-button v-on="on" v-bind:module="'roles'"></app-add-button>
		</template>
		<v-form ref="form" v-model="isvalid" lazy-validation>
			<v-card tile>
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-btn icon dark @click="close">
						<v-icon>close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>
							<v-flex xs12 sm12 md12>
								<v-text-field v-model="editedItem.roles" label="Role"></v-text-field>
							</v-flex>

						</v-layout>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" dark @click="save">Save</v-btn>
					<v-btn color="red darken-1"  text @click="close">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		isvalid: true,
	}),

	computed: {
		...mapGetters({
			currUser: 'auth/currUser',
			editedItem: 'roles/editedItem',
			formTitle: 'roles/formTitle',
			valid: 'roles/valid',
			dialog: 'roles/dialog',
			editedIndex: 'roles/editedIndex',
		})
	},

	methods: {
		close() {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('roles/setValid', false);
				this.$store.dispatch('roles/setDialog', false);
				this.$store.dispatch('roles/setEditedIndex', -1);
				this.isvalid = false;
			}, 2000);
		},

		async save() {
			this.$store.dispatch('roles/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if(this.isvalid){
				this.editedItem.edit_by = this.currUser.id;
				if (this.editedIndex > -1) {
					let data = { id: this.editedItem.id, roles: this.editedItem.roles, edit_by: this.currUser.id};
					this.$store.dispatch('roles/updateRoles', data);
				} else {
					this.$store.dispatch('roles/saveRoles', this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 
				3000);
			} else {
				this.$store.commit('app/SET_SNACK_BAR', { 
					'status': true, 
					'message': 'Please fill out all the required fields.', 
					'messagetype': 'error' 
				}, { root: true });
			}
		}
	}
};
</script>