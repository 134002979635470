<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="roles"
      class="elevation-1"
			dense
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Role Access Maintenance</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          color="primary"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          edit
        </v-icon>
        <v-icon
          color="red"
			class="mr-2"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
      </template>
    </v-data-table>
    <create-roles />
  </div>
</template>

<script>
import createRoles from './createRoles.vue';
import { mapGetters } from 'vuex';

export default {
	data(){
		return {
			nextPageRegularTrx: '',
		};
	},
	components: {
		'create-roles': createRoles
	},
	computed: {
		...mapGetters( {
			roles: 'roles/roles',
			headers: 'roles/headers',
			editedItem: 'roles/editedItem',
			defaultItem: 'roles/defaultItem',
			dialog: 'roles/dialog',
			editedIndex: 'roles/editedIndex',
			isLoading: 'roles/isLoading',
			valid: 'roles/valid',
		}),
	},
	mounted () {
		this.$store.dispatch('roles/getRoles');
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('roles/setEditedIndex', this.roles.indexOf(item));
			this.$store.dispatch('roles/setEditedItems', Object.assign({}, item));
			this.$store.dispatch('roles/setDialog', true);
			this.$store.dispatch('roles/setValid', true);
			this.$store.dispatch('roles/setformTitle', 'Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: `Remove ${item.roles} from the list?`,
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('roles/setEditedIndex', this.roles.indexOf(item));
					this.$store.dispatch('roles/deleteRoles', item);
				}
			});
		},
		async getRegTrx(){
			this.roles = this.roles.data;
			this.nextPageRegularTrx= this.roles.next_page_url;
			let page = 2;

			while (this.nextPageRegularTrx !== null){
				await this.$store.dispatch('roles/getData', page).then(response => {
					this.nextPageRegularTrx = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.roles.push(details);
					});
				});
				page++;
			}
		}
	}
};
</script>